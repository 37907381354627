import React, { createContext, useState, useContext } from "react";

const BullsDataContext = createContext();

export function BullsDataProvider({ children }) {
  // Functional states
  const [bullswipedLeft, setbullswipedLeft] = useState(false);
  const [bullswipedRight, setBullswipedRight] = useState(false);
  const [swipedLeftMarketplace, setSwipedLeftMarketplace] = useState(false);
  const [swipedRightMarketplace, setSwipedRightMarketplace] = useState(false);
  const [tutorialPopupDone, setTutorialPopupDone] = useState(true);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);

  // States
  const [userBulls, setUserBulls] = useState([]);
  const [filteredSelectBulls, setFilteredSelectBulls] = useState([]);
  const [bullsData, setBullsData] = useState([]);
  const [isBullBattleDataLoading, setIsBullBattleDataLoading] = useState(false);

  // Percentage values
  const [bullgreenSixPercentage, setBullgreenSixPercentage] = useState(0);
  const [bullredSixPercentage, setBullredSixPercentage] = useState(0);
  const [bullhostagePrice, setBullhostagePrice] = useState(0);

  // Select states
  const [bullselectedNoobieTraining, setBullselectedNoobieTraining] =
    useState(false);
  const [bullselectedBBTraining, setBullselectedBBTraining] = useState(false);
  const [selectedRescueBull, setSelectedRescueBull] = useState(false);
  const [bullselectedBBRescue, setBullselectedBBRescue] = useState(false);
  const [selectedBullBattle, setSelectedBullBattle] = useState(false);
  const [selectedBBWar, setSelectedBBWar] = useState(false);
  // Mission States
  const [bullisTrainingMission, setBullisTrainingMission] = useState(false);
  const [bullisRescueMission, setBullisRescueMission] = useState(false);
  const [bullisBattleMission, setBullisBattleMission] = useState(false);

  const [isWarMission, setIsWarMission] = useState(false);

  // Bear state
  const [bullHostagePopup, setBullHostagePopup] = useState(false);
  const [isPopupClosed, setIsPopupClosed] = useState(false);

  //War
  const [showBullsSidebar, setShowBullsSidebar] = useState(false);
  const [toggleBullWarStatus, setToggleBullWarStatus] = useState(false);

  const data = {
    userBulls, setUserBulls,
    bullsData, setBullsData,
    bullselectedNoobieTraining, setBullselectedNoobieTraining,
    bullselectedBBTraining, setBullselectedBBTraining,
    filteredSelectBulls, setFilteredSelectBulls,
    selectedRescueBull, setSelectedRescueBull,
    bullselectedBBRescue, setBullselectedBBRescue,
    bullisTrainingMission, setBullisTrainingMission,
    bullisRescueMission, setBullisRescueMission,
    bullisBattleMission, setBullisBattleMission,
    selectedBullBattle, setSelectedBullBattle,
    bullgreenSixPercentage, setBullgreenSixPercentage,
    bullredSixPercentage, setBullredSixPercentage,
    bullhostagePrice, setBullhostagePrice,
    bullHostagePopup, setBullHostagePopup,
    bullswipedLeft, setbullswipedLeft,
    bullswipedRight, setBullswipedRight,
    swipedLeftMarketplace, setSwipedLeftMarketplace,
    swipedRightMarketplace, setSwipedRightMarketplace,
    isPopupClosed, setIsPopupClosed,
    tutorialPopupDone, setTutorialPopupDone,
    isInfoPopupOpen, setIsInfoPopupOpen,
    isBullBattleDataLoading, setIsBullBattleDataLoading,
    selectedBBWar, setSelectedBBWar,
    isWarMission, setIsWarMission,
    showBullsSidebar, setShowBullsSidebar,
    toggleBullWarStatus, setToggleBullWarStatus
  }; // prettier-ignore

  return (
    <BullsDataContext.Provider value={data}>
      {children}
    </BullsDataContext.Provider>
  );
}

export function useBullsData() {
  const context = useContext(BullsDataContext);
  if (!context) {
    throw new Error("bb useBullsData must be used within a BullsDataProvider");
  }
  return context;
}
