import React, { useState } from "react";

// Assets
import BearTypes from "./BearTypes";
import BullTypes from "./BullTypes";
import { ReactComponent as InfoIcon } from "../../assets/media/icons/InfoIcon.svg";

const BearBullType = () => {
  const [soldierType, setSoldierType] = useState("bear");
  const [soldierName, setSoldierName] = useState("noobie");
  const [typeDescription, setTypeDescription] = useState(
    "Noobies have to be trained with Fighters to leave the castle (Your Wallet) and embark on missions."
  );

  const handleButtonClick = (type, name, desc) => {
    setSoldierType(type);
    setSoldierName(name);
    setTypeDescription(desc);
  };

  return (
    <div className="bear-bull-type-section panel">
      <div className="bear-bull-type-help-wrapper">
        <InfoIcon className="bear-bull-type-help-icon" />
        <p className="types-help-text">
          You can get information by clicking on the bears or bulls.
        </p>
      </div>
      <div className="bear-types-wrapper">
        <BearTypes
          handleButtonClick={handleButtonClick}
          soldierName={soldierName}
          soldierType={soldierType}
        />
        {/* <div className="bear-buttons-wrapper">
          {bearTypes.map((type, index) => (
            <button
              key={index}
              className={`bear-type-button bear-btn-${index} ${
                soldierType === "bear" && soldierName === type.name
                  ? "active"
                  : ""
              }`}
              onClick={() => handleButtonClick("bear", type.name, type.desc)}
            >
              <img
                style={{ backgroundColor: type.color }}
                draggable="false"
                className="bear-type-img"
                src={type.icon}
                alt=""
              />
              <p className="bear-type-name">{type.name} BEAR</p>
            </button>
          ))}
        </div> */}
      </div>

      <div className="bear-bull-type-center">
        {soldierType === "bear" ? (
          <img
            draggable="false"
            className="center-img"
            src="/assets/bear-bull-game/bull-type-img.png"
            alt=""
          />
        ) : (
          <img
            draggable="false"
            className="center-img"
            src="/assets/bear-bull-game/bull-type-img.png"
            alt=""
          />
        )}

        <div className="bear-bull-type-center-text">
          <p className="center-text-title">{soldierName}</p>
        </div>

        <p className="bear-bull-type-paragraph">{typeDescription}</p>
      </div>

      <div className="bull-types-wrapper">
        <BullTypes
          handleButtonClick={handleButtonClick}
          soldierName={soldierName}
          soldierType={soldierType}
        />

        {/* <div className="bull-buttons-wrapper">
          {bullTypes.map((type, index) => (
            <button
              key={index}
              className={`bull-type-button bull-btn-${index} ${
                soldierType === "bull" && soldierName === type.name
                  ? "active"
                  : ""
              }`}
              onClick={() => handleButtonClick("bull", type.name, type.desc)}
            >
              <img
                style={{ backgroundColor: type.color }}
                draggable="false"
                className="bull-type-img"
                src={type.img}
                alt=""
              />
              <p className="bull-type-name">{type.name} BULL</p>
            </button>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default BearBullType;
