import React from "react";

const NotActive = ({ supply }) => {
  return (
    <>
      <div className="sale-access-title-wrapper">
        <p className="access-page-mint-title">MINT - ACCESS PASS</p>

        <p className="access-page-mint-subtitle">
          Earliest Loyal Supporter's {supply} Soul Bound Oath to the Bear Games
          King. Holder of this Bear King's Sword will get 500,000 $EXP at Token
          Generation Event.
        </p>
      </div>
      <p className="salenotstarted">Sale has not started.</p>

      <div className="salenotstarted-progress" />
    </>
  );
};

export default NotActive;
