import React, { useState } from "react";
import { purchase } from "../../utils/accessPass/contract";
import { useAccount } from "wagmi";
import { notifyError, notifyInfo, notifySuccess } from "../../utils/functions";

const Mint = ({ price, hasMinted, supply, setTx, totalminted }) => {
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const progress = totalminted / supply;
  // This is the progress of the mint

  const handleMint = async () => {
    setLoading(true);
    try {
      const txn = await purchase(price);

      const txnInfo = await txn.wait();
      if (txnInfo.status === 1) {
        notifySuccess("Congratulations! You have minted");
        setLoading(false);
      } else {
        throw new Error("Transaction failed");
      }
    } catch (err) {
      setLoading(false);
      notifyError("Transaction failed");
    } finally {
      setTx({ isDone: true });
    }
  };

  return (
    <>
      <div className="sale-access-title-wrapper">
        <p className="access-page-mint-title">MINT - ACCESS PASS</p>

        <p className="access-page-mint-subtitle">
          Earliest Loyal Supporter's {supply} Soul Bound Oath to the Bear Games
          King. Holder of this Bear King's Sword will get 500,000 $EXP at Token
          Generation Event.
        </p>
      </div>

      <div className="access-pass-info-container">
        <div className="access-pass-info-top">
          <div className="access-pass-price-info-box">
            <p className="price-info">PRICE</p>
            <p className="price-eth-count">Ξ {price}</p>
          </div>

          <div className="separator-line-element" />

          <div className="supply-remaining">
            <p className="supply-info">SUPPLY REMAINING</p>
            <p className="supply-remaining-count">
              {totalminted} / {supply}
            </p>
          </div>
        </div>

        <div className="salestarted-progress">
          <div
            style={{ width: `${progress * 100}%` }}
            className="salestarted-inner-progress"
          />
        </div>
      </div>
      {hasMinted ? (
        <>
          <span className="already-minted-text">YOU HAVE ALREADY MINTED</span>
        </>
      ) : (
        <>
          {" "}
          <button
            className="access-pass-btn"
            onClick={handleMint}
            disabled={loading}
          >
            <img
              className="access-pass-btn-img"
              src="/assets/bear-bull-game/btn-sword.png"
              draggable="false"
              alt=""
            />
            <span>{loading ? "minting..." : "MINT"}</span>
          </button>{" "}
        </>
      )}
    </>
  );
};

export default Mint;
