import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MainContext } from "../context/context";
import useSound from "use-sound";
import { useMediaQuery } from "react-responsive";

import "./NavBar.scss";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";

// Assets
import LogoMain from "../../assets/media/images/bearbull.png";
import clickSound from "../../assets/sound/onClickSound.wav";
import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/media/icons/HamburgerMenu.svg";
import { ReactComponent as VolumeOn } from "../../assets/media/icons/volumeOn.svg";
import { ReactComponent as VolumeOff } from "../../assets/media/icons/volumeOff.svg";

const NavBar = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 950px)" });
  const [playClickSound] = useSound(clickSound, { volume: 0.325 });
  const { showNavBar, isSoundEnabled, setIsSoundEnabled } =
    useContext(MainContext);
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={showNavBar ? "navbar" : "navbar hide"}>
      <div
        className={
          isMobileSideMenuOpen ? "mobile-side-menu active" : "mobile-side-menu"
        }
      >
        <div className="close-btn-wrapper">
          <div
            onClick={() => setIsMobileSideMenuOpen(false)}
            className="close-btn"
          >
            <CloseIcon />
          </div>
        </div>

        {/*  <NavLink
          onClick={() => setIsMobileSideMenuOpen(false)}
          to="/allowlist-quests"
          rel="noreferrer"
        >
          WHITELIST QUESTS
        </NavLink> */}

        {/* <NavLink
          onClick={() => setIsMobileSideMenuOpen(false)}
          to="/home"
          rel="noreferrer"
        >
          HOME
        </NavLink>
        <NavLink
          onClick={() => setIsMobileSideMenuOpen(false)}
          to="/missions"
          rel="noreferrer"
        >
          MISSIONS
        </NavLink>
       
        <NavLink
          onClick={() => setIsMobileSideMenuOpen(false)}
          to="/marketplace"
          rel="noreferrer"
        >
          AUCTION
        </NavLink>
        <NavLink
          onClick={() => setIsMobileSideMenuOpen(false)}
          to="/marketplace"
          rel="noreferrer"
        >
          MARKETPLACE
        </NavLink>
        <NavLink
          onClick={() => setIsMobileSideMenuOpen(false)}
          to="/dashboard"
          rel="noreferrer"
        >
          DASHBOARD
        </NavLink> */}
      </div>

      <div className="nav-left-side">
        <img onClick={() => navigate("/")} src={LogoMain} alt="" />
        <NavLink onClick={isSoundEnabled && playClickSound} to="home">
          HOME
        </NavLink>
        <NavLink onClick={isSoundEnabled && playClickSound} to="allowlist">
          {!isTablet && "ALLOWLIST"} QUESTS
        </NavLink>

        {/* <NavLink onClick={isSoundEnabled && playClickSound} to="thebears">
          THE BEARS
        </NavLink>
        <NavLink onClick={isSoundEnabled && playClickSound} to="marketplace">
          MARKETPLACE
        </NavLink> */}
        {/* <NavLink onClick={isSoundEnabled && playClickSound} to="missions">
          MISSIONS

        </NavLink>
        
        <NavLink onClick={isSoundEnabled && playClickSound} to="dashboard">

        </NavLink>*/}

        {/* <NavLink onClick={isSoundEnabled && playClickSound} to="dashboard">

          DASHBOARD
        </NavLink>
        <NavLink onClick={isSoundEnabled && playClickSound} to="auction">
          AUCTION
        </NavLink>
        <NavLink onClick={isSoundEnabled && playClickSound} to="marketplace">
          MARKETPLACE
        </NavLink> */}
      </div>

      <div className="nav-right-side">
        <div
          onClick={() => {
            setIsSoundEnabled(!isSoundEnabled);
          }}
          className="volume-button-wrapper"
        >
          {isSoundEnabled ? <VolumeOn /> : <VolumeOff />}
        </div>
        <ConnectWallet />
        {/* Mobile side */}
        <div
          onClick={() => setIsMobileSideMenuOpen(true)}
          className="mobile-side"
        >
          <HamburgerIcon className="mobile-side-icon" />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
