import React, { useRef, useState } from "react";

// Assets
import { ReactComponent as EcosystemCurve } from "../../assets/svg/ecosystem-curve.svg";

const ecosystemData = [
  {
    id: 1,
    title: "TRAIN NOOBIES",
    description:
      "Train your Noobies to transform them into formidable fighters and send them on missions for $gold.",
    gifImg: "/assets/bear-bull-game/train-noobies.gif",
  },
  {
    id: 2,
    title: "Battle & Earn",
    description:
      "Deploy Fighters to the battlefield, launch strategized attacks and earn $GOLD for your hard-fought victories.",

    videoSrc: "/assets/bear-bull-game/battle-earn.mp4",
  },
  {
    id: 3,
    title: "Grow Your Army",
    description:
      "Use your $gold to mint more bears & bulls and grow your army. Purchase power-ups, or towards $TOKEN!",

    gifImg: "/assets/bear-bull-game/grow-your-army.gif",
  },
];

const BearBullEcosystem = () => {
  return (
    <div className="bear-bull-ecosystem panel">
      <div className="bear-bull-ecosystem-title-wrapper">
        <div className="ecosystem-title-bullbear">
          <p>ECO</p>
          {/* <img
            src="/assets/bear-bull-game/ecosystem-sword.png"
            draggable="false"
            alt="Ecosystem Sword"
          /> */}
          <p>SYSTEM</p>
        </div>
      </div>

      <div className="bearbull-ecosystem-cards-wrapper">
        {ecosystemData.map((data, index) => (
          <div className="ecosystem-card" key={data.id}>
            <div className="ecosystem-card-top">
              <div className="card-title-wrapper">
                <EcosystemCurve className="ecosystem-curve" />
                <p className="ecosystem-card-title">{data.title}</p>
                {index === 1 && (
                  <img
                    className="card-coin"
                    draggable="false"
                    src="/assets/bear-bull-game/coin.gif"
                  />
                )}
              </div>

              {data.videoSrc ? (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  preload="metadata" // Preload only metadata initially
                  poster={data.staticImg}
                >
                  <source src={data.videoSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  loading="lazy"
                  src={data.gifImg}
                  alt={data.title}
                  draggable="false"
                />
              )}
            </div>

            <p className="ecosystem-card-description">{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BearBullEcosystem;
