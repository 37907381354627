import React, { useState } from "react";
import { notifyError, notifySuccess } from "../../utils/functions";
import { useNavigate } from "react-router-dom";

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const BearBullAllowlist = () => {
  const navigate = useNavigate();
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    setSubscribeEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (isValidEmail(subscribeEmail)) {
      const api_response = await fetch(
        process.env.REACT_APP_BASEURL + "/users/waitlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email_address: subscribeEmail }),
        }
      );
      const result = await api_response.json();
      if (result.status) {
        setSubscribeEmail("");
        notifySuccess("Thank you for subscribing!");
        setIsLoading(false);
      }
    } else {
      notifyError("Please enter a valid email address.");
      setIsLoading(false);
    }
  };

  return (
    <section className="bear-bull-allowlist panel">
      <img
        onClick={() => navigate("/allowlist")}
        src="/assets/bear-bull-game/allowlist-two.png"
        className="allowlist-container-img"
        alt=""
      />

      <div className="bear-bull-game-cta-btn-wrapper">
        <p className="cta-text-bearbull">
          BE THE FIRST TO RECEIVE OUR LATEST UPDATES & NEWS!
        </p>

        <div className="bearbull-input-wrapper">
          <div className="home-email-input-wrapper">
            <input
              onChange={handleEmailChange}
              value={subscribeEmail}
              placeholder="YOUR EMAIL ADDRESS"
              className="email-input"
              type="email"
            />
          </div>
          <div onClick={handleSubmit} className="home-cta-btn">
            {isLoading ? <div className="loader" /> : "REQUEST CARRIER PIGEON"}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BearBullAllowlist;
