import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import throttle from "lodash.throttle";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import useSound from "use-sound";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { useMediaQuery } from "react-responsive";

// Context
import { MainContext } from "../../components/context/context";

// Components
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import Loading from "./Loading.jsx";

// Assets
import { ReactComponent as Curve } from "../../assets/svg/curve.svg";
import { ReactComponent as ChooseYourSide } from "../../assets/svg/choose-your-side-1.svg";
import { ReactComponent as ScrollToIcon } from "../../assets/svg/scroll-to-2.svg";
import { ReactComponent as NoAnnouncementCurve } from "../../assets/svg/no-announcement.svg";
import { ReactComponent as VolumeOn } from "../../assets/media/icons/volumeOn.svg";
import { ReactComponent as VolumeOff } from "../../assets/media/icons/volumeOff.svg";

// Sounds
import hoverSound from "../../assets/sound/WindSwoosh.wav";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
const BearBullChoose = ({ setCurrentSection }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isSmallerDevice = useMediaQuery({ query: "(max-width: 1024px)" });
  const navigate = useNavigate();
  const announcementText = "Allowlist Quests";

  // If the announcement text is empty, the coin gif will be displayed instead
  const { isSoundEnabled, setIsSoundEnabled } = useContext(MainContext);

  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [bearKingGif, setBearKingGif] = useState(
    "assets/bear-bull-game/landing-bearking-idle.gif"
  );
  const [bullKingGif, setBullKingGif] = useState(
    "/assets/bear-bull-game/landing-bullking-idle.gif"
  );

  const [playHoverSound] = useSound(hoverSound, {
    volume: 0.25,
    interrupt: true,
  });

  const [chosenSide, setChosenSide] = useState(null);

  const handleMouseEnter = () => {
    isSoundEnabled && playHoverSound();
    setBearKingGif(
      "/assets/bear-bull-game/bearking-outlined-transitiontoselected.gif"
    );

    setTimeout(() => {
      setBearKingGif("/assets/bear-bull-game/bearking-outlined-selected.gif");
    }, 350);
  };

  const handleMouseLeave = throttle(() => {
    setBearKingGif(
      "/assets/bear-bull-game/bearking-nooutline-transitiontoidle.gif"
    );

    setTimeout(() => {
      setBearKingGif("/assets/bear-bull-game/landing-bearking-idle.gif");
    }, 300);
  }, 310);

  const handleMouseEnterBull = throttle(() => {
    isSoundEnabled && playHoverSound();
    setBullKingGif(
      "/assets/bear-bull-game/bullking-outlined-transitiontoselected.gif"
    );

    setTimeout(() => {
      setBullKingGif("/assets/bear-bull-game/bullking-outlined-selected.gif");
    }, 250);
  }, 260);

  const handleMouseLeaveBull = throttle(() => {
    if (chosenSide !== "bull") {
      setBullKingGif(
        "/assets/bear-bull-game/bullking-nooutline-transitiontoidle.gif"
      );
      setTimeout(() => {
        setBullKingGif("/assets/bear-bull-game/landing-bullking-idle.gif");
      }, 300);
    }
  }, 310);

  useEffect(() => {
    let chooseTrigger;
    document.title = "Bear & Bull Game";

    if (!isMobile) {
      chooseTrigger = ScrollTrigger.create({
        trigger: ".bear-bull-choose",
        start: "top top",
        bottom: "bottom+=1000px top",
        pin: true,
      });
    }
    return () => {
      if (chooseTrigger) {
        chooseTrigger.kill();
      }
    };
  }, []);

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  useEffect(() => {
    const imgTagsSources = [
      "assets/bear-bull-game/landing-bearking-idle.gif",
      "/assets/bear-bull-game/landing-bullking-idle.gif",
      "/assets/bear-bull-game/bearking-outlined-transitiontoselected.gif",
      "/assets/bear-bull-game/bearking-outlined-selected.gif",
      "/assets/bear-bull-game/bearking-nooutline-transitiontoidle.gif",
      "/assets/bear-bull-game/bullking-outlined-transitiontoselected.gif",
      "/assets/bear-bull-game/bullking-outlined-selected.gif",
      "/assets/bear-bull-game/bullking-nooutline-transitiontoidle.gif",
    ];

    const cssBackgroundSources = [
      "/assets/bear-bull-game/landing-riverside.gif",
      "/assets/bear-bull-game/cloud-left.png",
      "/assets/bear-bull-game/cloud-right.png",
      "/assets/bear-bull-game/castle-left.gif",
      "/assets/bear-bull-game/castle-right.gif",
      "/assets/bear-bull-game/landing-cliffright.png",
      "/assets/bear-bull-game/landing-cliffleft.png",
    ];

    Promise.all([...imgTagsSources, ...cssBackgroundSources].map(loadImage))
      .then(() => setImagesLoaded(true))
      .catch((err) => console.error("Error loading images", err));
  }, []);

  const handleScrollTo = () => {
    gsap.to(window, {
      duration: 1.75,
      scrollTo: ".bear-bull-roadmap",
      onComplete: () => {
        setCurrentSection(1);
      },
    });
  };

  // const handleChosenBear = () => {
  //   document.body.style.overflow = "hidden";
  //   document.body.style.height = "100vh";

  //   setTimeout(() => {
  //     navigate("/bears-hall");
  //   }, 1000);
  // };

  return (
    <>
      <div
        className={
          imagesLoaded
            ? "initial-loading-wrapper hide"
            : "initial-loading-wrapper"
        }
      >
        <img
          draggable="false"
          className="initial-loading-gif"
          src="/assets/loading.gif"
          alt="loading"
        />
      </div>
      <ParallaxProvider>
        <section className="bear-bull-choose">
          <div className="header-top-wrapper">
            <div className="buttons-wrapper">
              <button
                onClick={() =>
                  window.open("https://twitter.com/bearandbullgame", "_blank")
                }
                className="twitter-btn bear-bull-btn"
              >
                <img
                  src="/assets/TwitterPixel.png"
                  alt=""
                  className="twitter-icon"
                  draggable="false"
                />
              </button>
              <button
                onClick={() =>
                  window.open("https://discord.gg/hUDGwj53YM", "_blank")
                }
                className="discord-btn bear-bull-btn"
              >
                <img
                  src="/assets/DiscordPixel.png"
                  alt=""
                  className="discord-icon"
                  draggable="false"
                />
              </button>

              {/* TODO: UNCOMMENT THIS */}
              {/* <button
                onClick={() =>
                  window.open("https://dev.bears-btc-sim.pages.dev/", "_blank")
                }
                className="game-btn bear-bull-btn"
              >
                ETH SIM
              </button>
              <button
                onClick={() =>
                  window.open("https://barbearians-pl.pages.dev", "_blank")
                }
                className="game-btn bear-bull-btn"
              >
                PNL
              </button> */}
            </div>
            <div className="choose-your-side-container">
              <div className="choose-your-side-wrapper">
                <p className="choose-your-side-text">CHOOSE YOUR SIDE</p>
                <ChooseYourSide className="choose-your-side-svg" />
              </div>
            </div>

            <div className="bear-bull-nav-right-side">
              <div
                onClick={() => {
                  setIsSoundEnabled(!isSoundEnabled);
                }}
                className="volume-button-wrapper"
              >
                {isSoundEnabled ? <VolumeOn /> : <VolumeOff />}
              </div>

              <div className="connect-wallet-bear-bull-game">
                <ConnectWallet />
              </div>
            </div>
          </div>

          <div className="bear-bull-choose-sky" />

          <Parallax
            translateY={!isMobile ? 2.5 : 0}
            className="bear-bull-riverside"
          />

          <Parallax translateY={1} className="clouds-left" />

          <Parallax translateY={1} className="clouds-right" />

          <Parallax translateY={3.5} className="bear-bull-castleleft" />

          <Parallax translateY={3.5} className="bear-bull-castleright" />

          <Parallax className="bear-bull-cliffright" />

          <Parallax className="bear-bull-cliffleft" />

          {/* Characters */}
          <Parallax className="bear-king-wrapper">
            <img
              src="/assets/bear-bull-game/gray-lock.png"
              draggable="false"
              className="gray-lock"
              alt=""
            />
            <img
              draggable="false"
              src={bearKingGif}
              alt=""
              className="landing-bearking-idle"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </Parallax>

          <Parallax
            onMouseEnter={handleMouseEnterBull}
            onMouseLeave={handleMouseLeaveBull}
            className="bull-king-wrapper parallax"
            data-depth="0.325"
          >
            <img
              src="/assets/bear-bull-game/gray-lock.png"
              draggable="false"
              className="gray-lock"
              alt=""
            />
            <img
              draggable="false"
              src={bullKingGif}
              alt=""
              className="landing-bullking-idle"
            />
          </Parallax>
          {/* Characters end */}

          <div className="bottom-header-wrapper">
            <p className="bear-bull-game-logo">
              BEAR <span>&</span> BULL GAME
            </p>
            <div
              onClick={() => navigate("/allowlist")}
              className={
                announcementText
                  ? "announcement-wrapper"
                  : "announcement-wrapper empty-announcement"
              }
            >
              {announcementText && (
                <img
                  src="/assets/bear-bull-game/btn-sword.png"
                  draggable="false"
                  className="announcement-img"
                  alt=""
                />
              )}
              {announcementText ? (
                <p className="announcement-text">{announcementText}</p>
              ) : (
                <>
                  <NoAnnouncementCurve className="announcement-curve" />
                  <img
                    src="/assets/bear-bull-game/coin.gif"
                    draggable="false"
                    className="announcement-coin-gif"
                  />
                </>
              )}

              {announcementText !== "" && (
                <div>
                  <Curve className="announcement-curve left" />
                  <Curve className="announcement-curve right" />
                </div>
              )}
            </div>

            <button onClick={handleScrollTo} className="next-section-btn">
              <ScrollToIcon className="scroll-to-icon" />
            </button>
          </div>
        </section>
      </ParallaxProvider>
    </>
  );
};

export default BearBullChoose;
