import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { ethers } from "ethers";
import { MainContext } from "../../components/context/context";
// Components
import Mint from "./Mint";
import NotActive from "./NotActive";
import SoldOut from "./SoldOut";
import {
  activeSale,
  alreadyMinted,
  mintPrice,
  totalSupply,
  purchase,
  MintedCount,
} from "../../utils/accessPass/contract";
import { useAccount } from "wagmi";

const AccessPass = () => {
  // 1. notActive
  // 2. active
  // 3. soldOut
  // You can use the mintStatus to conditionally render the Mint component
  const { setShowNavBar } = useContext(MainContext);
  const [currentStatus, setCurrentStatus] = useState("notActive");
  const [price, setPrice] = useState();
  const [hasMinted, setHasMinted] = useState();
  const [supply, setSupply] = useState(43);
  const [totalminted, setTotalMinted] = useState(0);
  const [isActive, setIsActive] = useState();
  const [loading, setLoading] = useState(true);
  const [tx, setTx] = useState({
    isDone: false,
  });
  const { address } = useAccount();
  // This is the current status of the mint

  useEffect(() => {
    document.title = "Bear & Bull Game - Access Pass";
    setShowNavBar(true);
    if (address) {
      callContract();
    }
  }, [address, tx.isDone]);

  const callContract = async () => {
    try {
      const [sale_active, is_minted, mint_price, total_supply, total_Minted] =
        await Promise.all([
          activeSale(),
          alreadyMinted(address),
          mintPrice(),
          totalSupply(),
          MintedCount(),
        ]);
      setIsActive(sale_active);
      setHasMinted(is_minted);
      setPrice(mint_price);
      setSupply(total_supply);
      setTotalMinted(total_Minted);
      if (total_Minted === total_supply) {
        setCurrentStatus("soldOut");
      } else {
        setCurrentStatus("notActive");
      }
    } catch (error) {
      console.error("Error fetching contract data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="access-pass">
      <div className="access-pass-content-wrapper">
        <div className="access-pass-content-left">
          <img
            src="/assets/bear-bull-game/mint-access-sword.gif"
            draggable="false"
            className="access-page-sword-gif"
            alt=""
          />
        </div>

        <div className="access-pass-content-right">
          {loading ? (
            <div className="accessing-loader">
              <span>ACCESSING</span>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          ) : (
            <>
              {currentStatus === "notActive" && <NotActive supply={supply} />}
              {currentStatus === "active" && (
                <Mint
                  price={price}
                  hasMinted={hasMinted}
                  supply={supply}
                  setTx={setTx}
                  totalminted={totalminted}
                />
              )}
              {currentStatus === "soldOut" && <SoldOut supply={supply} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessPass;
