import React from "react";

// Assets
import { ReactComponent as CoinBorder } from "../../assets/svg/coin-border.svg";
import { ReactComponent as TeamCardCurve } from "../../assets/svg/team-card-curve.svg";

// TODO: Add Future Fragment Twitter link.
const bearBullTeam = [
  {
    nickname: "Pooria",
    title: "Founder",
    bio: `Notorious Spatula
    Burnt 250 ETH in gas wars flipping NFTs
    Ex @Lvcidia`,
    image: "/assets/bear-bull-game/pooria.png",
    memberTwitter: "https://twitter.com/0xPooria",
  },
  {
    nickname: "Future Fragment",
    title: "Art Studio",
    bio: "A Creative Studio Specializing in Pixel Art, Animation & Game Development",
    image: "/assets/bear-bull-game/futurefragment.png",
    memberTwitter: "https://twitter.com/futurefragment",
  },
  {
    nickname: "RetroByte",
    title: "Game Dev",
    bio: "A full stack nft gaming studio focused on Reviving the Nostalgic Essence of NFTs",
    image: "/assets/bear-bull-game/retrobyte.png",
    memberTwitter: "https://twitter.com/R3troByte",
  },
  {
    nickname: "Carnation",
    title: "Community",
    bio: "Former professional Overwatch player & team manager turned Web3 enthusiast.",
    image: "/assets/bear-bull-game/ali.png",
    memberTwitter: "https://twitter.com/PooriaIntern",
  },
  {
    nickname: "VainGlorious.ETH",
    title: "Game Advisory",
    bio: `Founder of @Westcoastnfts
    the tech studio behind
    doodles, mfers & more`,
    image: "/assets/bear-bull-game/adil.png",
    memberTwitter: "https://twitter.com/VaingloriousETH",
  },
];

const BearBullTeam = () => {
  return (
    <section className="bear-bull-team panel">
      <p className="bear-bull-title">[ THE CORE TEAM ]</p>

      <div className="cards-wrapper">
        {bearBullTeam.map((member, index) => (
          <div key={member?.nickname} className="team-card-wrapper">
            <div className="team-card-top">
              <div className="member-title-wrapper">
                <TeamCardCurve className="team-card-curve" />
                <p className="member-title">{member?.title}</p>
              </div>
              <img
                src={member?.image}
                alt=""
                className="member-image"
                draggable="false"
              />
            </div>

            <div className="team-card-bottom">
              <div className="member-team-name-wrapper">
                <p className="member-name">{member?.nickname}</p>
              </div>

              <p className="member-bio">{member?.bio}</p>
            </div>

            <div
              onClick={() => {
                window.open(member?.memberTwitter, "_blank");
              }}
              className="member-social-btn"
            >
              <img
                src="/assets/bear-bull-game/team-twitter.png"
                draggable="false"
                alt=""
              />
            </div>
          </div>
        ))}
      </div>

      <div className="coin-border-wrapper">
        <CoinBorder className="coin-border" />
        <img
          className="coin-border-icon"
          src="/assets/bear-bull-game/coin.gif"
          draggable="false"
          alt=""
        />
      </div>
    </section>
  );
};

export default BearBullTeam;
