import React, { useState, lazy, Suspense, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga4";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ReactPlayer from "react-player";
import { useAccount } from "wagmi";

import { MainContext } from "./components/context/context";

import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";

import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { goerli, mainnet, sepolia } from "wagmi/chains";

// Pages
import ProtectedRoute from "./ProtectedRoute";
import NavBar from "./components/NavBar/NavBar";
import Error from "./pages/ErrorPage/Error";
import Intro from "./pages/Intro/Intro";
import HallScene from "./pages/Intro/HallScene";
import Home from "./pages/Home/Home";
import Marketplace from "./pages/Marketplace/Marketplace";
import Missions from "./pages/Missions/Missions";
import OngoingMission from "./pages/Dashboard/OngoingMission/OngoingMission";
import WhitelistQuests from "./pages/WhitelistQuests/WhitelistQuests";
import UserProvider from "./auth/provider/user";
import DiscordLoading from "./auth/pages/discordLoading";
import Loader from "./components/Fallback/Loader";

// Assets
import MainSound from "./assets/sound/mainSong.mp3";
import { ToastContainer } from "react-toastify";
import { BearsDataProvider } from "./components/context/bearsContext";

//bull
import { BullsDataProvider } from "./components/context/bullContext";
import BearBullLanding from "./pages/bear-bull-game/BearBullLanding";
import AccessPass from "./pages/access-pass/AccessPass";
const LazyWhitelistQuests = lazy(() =>
  import("./pages/bear-bull-game/WhitelistQuests/whitelist-quests")
);

// Lazy
// const LazyAllowlistSale = lazy(() =>
//   import("./pages/AllowlistSale/AllowlistSale")
// );
// const LazyAuctionCheck = lazy(() => import("./pages/Auction/AuctionCheck"));
// const LazyPrivateSale = lazy(() => import("./pages/PrivateSale/PrivateSale"));
// const LazyTheAllowlist = lazy(() =>
//   import("./pages/TheAllowlist/TheAllowlist")
// );
// const LazyDashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
// const LazyClaimNFT = lazy(() => import("./pages/ClaimNFT/ClaimNFT"));
// const LazySelectPage = lazy(() =>
//   import("./pages/Dashboard/sections/SelectPage")
// );
// const LazyTerms = lazy(() => import("./pages/Terms/Terms"));
// const LazyFAQ = lazy(() => import("./pages/Terms/FAQ"));
// const LazyGraveyard = lazy(() => import("./pages/Graveyard/Graveyard"));
// const LazyBattleMission = lazy(() =>
//   import("./pages/BattleMission/BattleMission")
// );
// const LazyTrainingMission = lazy(() =>
//   import("./pages/TrainingMission/TrainingMission")
// );
// const LazyRescueMission = lazy(() =>
//   import("./pages/RescueMission/RescueMission")
// );
// const LazyWarGame = lazy(() => import("./pages/WarGame/WarGame"));
// const LazyMarketplace = lazy(() => import("./pages/Marketplace/Marketplace"));
// //bull
// const LazyBUllClaim = lazy(() => import("./pages/Bull/ClaimNFT/ClaimNFT"));

// const LazyBUllDashboard = lazy(() =>
//   import("./pages/Bull/Dashboard/Dashboard")
// );
// const LazyBullSelectPage = lazy(() =>
//   import("./pages/Bull/Dashboard/sections/SelectPage")
// );
// const LazyBullBattleMission = lazy(() =>
//   import("./pages/Bull/BattleMission/BattleMission")
// );
// const LazyBullTrainingMission = lazy(() =>
//   import("./pages/Bull/TrainingMission/TrainingMission")
// );
// const LazyBullRescueMission = lazy(() =>
//   import("./pages/Bull/RescueMission/RescueMission")
// );
// const LazyBullGraveyard = lazy(() =>
//   import("./pages/Bull/Graveyard/Graveyard")
// );
// const LazyBearBullLanding = lazy(() =>
//   import("./pages/bear-bull-game/BearBullLanding")
// );

const chains = [mainnet];
// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "5935b142975cc9c22faf48e3c5c8cda0" }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

// Google Analytics
ReactGA.initialize("G-LELHXZVSE7");

function App() {
  const { isConnected } = useAccount();
  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  const [showNavBar, setShowNavBar] = useState(true);
  const [playMainSong, setPlayMainSong] = useState(false);
  const [isIntroDone, setIsIntroDone] = useState(false);
  const [volume, setVolume] = useState(0.05);
  const location = useLocation();

  const data = {
    isSoundEnabled, setIsSoundEnabled,
    showNavBar, setShowNavBar,
    isIntroDone, setIsIntroDone,
    setPlayMainSong,
  }; // prettier-ignore

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location]);

  const isHome = location.pathname === "/";

  return (
    <MainContext.Provider value={data}>
      <UserProvider>
        <BullsDataProvider>
          <BearsDataProvider>
            <WagmiConfig client={wagmiClient}>
              <ReactPlayer
                url={MainSound}
                playing={isSoundEnabled}
                loop={true}
                controls={false}
                muted={false}
                volume={volume}
                style={{
                  display: "none",
                }}
              />
              <UserProvider>
                <ToastContainer limit={2} />
                <NavBar />
                <AnimatePresence mode="wait">
                  <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<BearBullLanding />} />
                    <Route path="/*" element={<Navigate to="/" />} />
                    <Route path="home" element={<BearBullLanding />} />

                    <Route
                      path="access-pass"
                      element={
                        isConnected ? <AccessPass /> : <BearBullLanding />
                      }
                    />
                    <Route path="allowlist" element={<LazyWhitelistQuests />} />

                    {/* TODO: UNCOMMENT THIS */}
                    {/* <Route
                      path="whitelist-quests"
                      element={<WhitelistQuests />}
                    /> */}

                    {/* <Route path="access-pass" element={isConnected ? <AccessPass /> : <BearBullLanding/>} /> */}
                    {/* <Route path="bears-hall" element={<HallScene />} /> */}
                    {/* <Route
                      path="marketplace"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyMarketplace />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="market"
                      element={
                        <ProtectedRoute>
                          <Navigate to="/marketplace" />
                        </ProtectedRoute>
                      }
                    /> */}
                    {/* <Route
                      path="auction"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyAuctionCheck />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="private-sale"
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<Loader />}>
                            <LazyPrivateSale />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    /> */}
                    {/* <Route
                      path="theallowlist"
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<Loader />}>
                            <LazyTheAllowlist />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    /> */}
                    {/* <Route path="whitepaper" element={<Whitepaper />} /> */}
                    {/* <Route path="missions" element={<Missions />} /> */}
                    {/* <Route
                      path="dashboard"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyDashboard />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="allowlist-sale"
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<Loader />}>
                            <LazyAllowlistSale />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    /> */}
                    {/* <Route
                      path="claim-nft"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyClaimNFT />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="select-bears"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazySelectPage />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="ongoing-mission"
                      element={<OngoingMission />}
                    /> */}
                    {/* <Route
                      path="battle-mission/:id"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBattleMission />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="training-mission/:trainingId"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyTrainingMission />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="rescue-mission/:rescueId"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyRescueMission />
                        </Suspense>
                      }
                    /> */}

                    {/* <Route
                      path="graveyard"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyGraveyard />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route path="/discord-auth" element={<DiscordLoading />} /> */}
                    {/* <Route
                      path="terms"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyTerms />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="faq"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyFAQ />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="wargames"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyWarGame />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route path="/*" element={<Error />} /> */}
                    {/* <Route path='/twitter-auth' element={<TwitterLoading />} /> */}
                    {/* // bull */}
                    {/* <Route
                      path="bull/claim"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBUllClaim />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="bull/dashboard"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBUllDashboard />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="bull/select-bulls"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBullSelectPage />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="bull/battle-mission/:id"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBullBattleMission />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="bull/training-mission/:trainingId"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBullTrainingMission />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="bull/rescue-mission/:rescueId"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBullRescueMission />
                        </Suspense>
                      }
                    /> */}
                    {/* <Route
                      path="bull/graveyard"
                      element={
                        <Suspense fallback={<Loader />}>
                          <LazyBullGraveyard />
                        </Suspense>
                      }
                    /> */}
                  </Routes>
                </AnimatePresence>
                <Web3Modal
                  projectId="5935b142975cc9c22faf48e3c5c8cda0"
                  ethereumClient={ethereumClient}
                  themeColor="blackWhite"
                  themeMode="dark"
                />
              </UserProvider>
            </WagmiConfig>
          </BearsDataProvider>
        </BullsDataProvider>
      </UserProvider>
    </MainContext.Provider>
  );
}

export default App;
