import { toast } from "react-toastify";

export function truncateString(string, length) {
  if (string.length <= length) {
    return string;
  } else {
    const truncatedString = string.slice(0, length - 3) + "...";
    return truncatedString;
  }
}

// Toast notifications
export const notifyError = (message) => {
  toast.error(message, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    className: "toastify-error",
  });
};

export const notifySuccess = (message) => {
  toast.success(message, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    className: "toastify-error",
  });
};

export const notifyInfo = (message) => {
  toast.info(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    className: "toastify-success",
  });
};
