import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const BearBullRoadmap = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <div className="bear-bull-roadmap panel">
      {isMobile ? (
        <TransformWrapper wheel={{ wheelDisabled: true }} initialScale={1}>
          <TransformComponent>
            <img
              draggable="false"
              src="/assets/bear-bull-game/2024-roadmap-2.png"
              className="bear-bull-roadmap-img"
              alt=""
            />
          </TransformComponent>
        </TransformWrapper>
      ) : (
        <img
          draggable="false"
          src="/assets/bear-bull-game/2024-roadmap-2.png"
          className="bear-bull-roadmap-img"
          alt=""
        />
      )}
    </div>
  );
};

export default BearBullRoadmap;
