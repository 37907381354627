import { ethers } from "ethers";
import { abi } from "./abi.js";
import bigInt from "big-integer";

// testnet sepolia
const address = "0x0de2b265eeab8a4438813ad45cca7a2718344747";

const initContracts = () => {
  if (!window.ethereum) {
    const url = "https://sepolia.infura.io/v3/c1e85a77b5f4477dae4d0f02345483bc";
    var provider = new ethers.providers.JsonRpcProvider(url);
    const contract = new ethers.Contract(address, abi, provider);
    return { contract };
  } else {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(address, abi, provider.getSigner());

    return { contract };
  }
};

const mintPrice = async () => {
  const { contract } = initContracts();
  const mintPrice = await contract.mintPrice();
  const priceInEther = ethers.utils.formatEther(mintPrice);
  return priceInEther;
};

const totalSupply = async () => {
  const { contract } = initContracts();

  try {
    // Call the function using its full signature
    const totalSupply = await contract.maxSupply();
    const bigNum = totalSupply.toNumber();
    return bigNum;
  } catch (error) {
    console.error("Error fetching total supply:", error);
    throw error; // or handle the error as needed
  }
};

const activeSale = async () => {
  const { contract } = initContracts();
  const activeSale = await contract.saleActive();
  return activeSale;
};

const alreadyMinted = async (wallet) => {
  const { contract } = initContracts();
  const alreadyMinted = await contract.minted(wallet);
  return alreadyMinted;
};

const purchase = async (price) => {
  const { contract } = initContracts(); // This should get the contract with signer if window.ethereum is available

  // Convert the price from Ether to Wei
  const priceInWei = ethers.utils.parseEther(price.toString());

  // Set up the transaction parameters
  const transactionParams = {
    value: priceInWei,
  };

  // Make the transaction
  try {
    const transaction = await contract.purchase(transactionParams);
    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.error("Error during purchase transaction:", error);
  }
};
const MintedCount = async () => {
  const { contract } = initContracts();

  try {
    // Call the function using its full signature
    const totalSupply = await contract["totalSupply()"]();
    const bigNum = totalSupply.toNumber();
    return bigNum;
  } catch (error) {
    console.error("Error fetching total supply:", error);
    throw error; // or handle the error as needed
  }
};

export {
  purchase,
  mintPrice,
  totalSupply,
  alreadyMinted,
  activeSale,
  MintedCount,
};
